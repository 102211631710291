<template>
  <div class="user_safety">
    <NavBar 
      :title="typeName" 
      @click-left="goBack"
      :left-arrow="isWeixin_status == false"
    />
    <Empty v-if="empty" :buttonText="buttonText" :EmptyMsg="EmptyMsg" @clickButton="gotoVerify"></Empty>
    <div class="phone_line" @click="show_action = true" v-if="!empty&&typeName!='实名认证'">
      <div class="label">{{typeName}}</div>
      <div class="value">{{typeName == "手机号码"? userPhoneNumber:""}}</div>
      <div class="arrow">
        <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
      </div>
    </div>
    <ActionSheet
      v-model="show_action"
      :actions="actions"
      cancel-text="取消"
      :round="false"
      @select="onSelect"
    />
  </div>
</template>
<script>
import Empty from "@/components/public/SettingEmpty";
import { ActionSheet, NavBar } from 'vant';
import Setting from "@/api/setting"
export default {
  components: {
    Empty,
    NavBar,
    ActionSheet
  },
  data() {
    return {
      typeName:"",
      empty:true,
      show_action:false,
      buttonText:"",
      EmptyMsg:"",
      userPhoneNumber:'15020935375',
      actions:[],
    };
  },
  mounted(){
    this.init()
  },
  methods:{
    goBack() {
      this.$router.push('/vip');
    },
    init(){
      var type = sessionStorage.getItem('verifyType')
      console.log(type)
      if(type == "phoneNumber"){
        this.IfVerify("mobile")
        this.typeName = "手机号码"
        this.buttonText = "绑定手机号码"
        this.EmptyMsg = "很抱歉，您暂未绑定手机号码哦~"
        this.actions = [
          { name: '修改手机号码(原先号码可用)' },
          { name: '修改手机号码(原先号码不可用)' }
        ]
      }else if(type == "password"){
        this.IfVerify("password")
        this.typeName = "登陆密码"
        this.buttonText = "设置登录密码"
        this.EmptyMsg = "很抱歉，您暂未设置登录密码哦~"
        this.actions = [
          { name: '修改登录密码' },
          { name: '忘记登陆密码' }
        ]
      }else if(type == "question"){
        this.typeName = "密保问题"
        this.buttonText = "设置密保问题"
        this.EmptyMsg = "很抱歉，您暂未设置密保问题哦~"
        this.actions = [
          { name: '修改密保问题' },
          { name: '忘记密保问题' }
        ]
      }else if(type == "realName"){
        this.IfRadlName()
        this.typeName = "实名认证"
        this.buttonText = "立即实名认证"
        this.EmptyMsg = "很抱歉，您暂未进行实名认证哦~"
      }else if(type == "dealPassword"){
        this.judgePayPass();
        this.typeName = "交易密码"
        this.buttonText = "设置交易密码"
        this.EmptyMsg = "很抱歉，您暂未设置交易密码哦~"
        this.actions = [
          { name: '修改交易密码' },
          { name: '忘记交易密码' }
        ]
      }
    },
    //判断用户是否已设置
    /**
     * 登陆密码 password
     * 手机号 mobile
     * 支付密码 pay_passwrod
     */
    async IfVerify(type) {
      try {
        const res = await Setting.IfVerify(type);
        if (res.code == 200) {
          this.empty = res.msg == "已设置"? false:true;
        } else{
          console.log(res);
        }
      } catch(err) {
        console.log(err);
      }
    },
    async judgePayPass() {
      try {
        const res = await Setting.judgePayPass();
        if (res.code == 200) {
          this.empty = res.msg == "已设置交易密码"? false:true;
        } else{
          console.log(res);
        }
      } catch(err) {
        console.log(err);
      }
    },
    async IfRadlName(){
      try {
        const res = await Setting.IfIdCard();
        if (res.code == 400) {
          this.empty = true;
        } else{
          console.log(res);
        }
      } catch(err) {
        console.log(err);
      }
    },
    gotoVerify(){
      if(this.typeName == "密保问题"){
        this.$router.push('/question')
      }else if(this.typeName == "实名认证"){
        this.$router.push('/realName')
      }else{
        this.$router.push({path:'/verify',query:{type: sessionStorage.getItem('verifyType')}})
      }
    },
    onSelect(item){
      console.log(item.name)
      var t = "";
      if(item.name == '修改登录密码'){
        t = "old_password";
      }else if(item.name == '修改手机号码(原先号码可用)'){
        t = "oldPhoneNumber";
      }else if(item.name == '修改交易密码'){
        t = "oldDealPassword";
      }else if(item.name == '忘记交易密码'){
        t = "dealPassword";
        this.show_action = false;
        sessionStorage.setItem("path", '/vip');
        this.$router.push({path:'/authentication'})
        return;
      }
      this.show_action = false;
      sessionStorage.setItem("path", '/vip');
      this.$router.push({path:'/verify',query:{type: t}})
    }
  },

};
</script>
<style lang="scss" scoped>
.user_safety {
  width: 100%;
  height: 100vh;
  font-family: PingFang SC;
  padding-bottom: 1px;
  background-color: #f4f4f4;
  position: relative;
  overflow: hidden;
  .phone_line{
    display: flex;
    height: 45px;
    line-height: 45px;
    background-color: #fff;
    padding: 0 12px;
    width:100%;
    .label{
      color:#333;
      font-size: 14px;
    }
    .value{
      color:#999;
      font-size: 13px;
      text-align: right;
      flex: 1;
    }
    .arrow{
      width:7px;
      margin-left: 3px;
    }
  }
}
</style>
