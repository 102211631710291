<template>
  <div class="empty">
    <div class="empty_msg">{{EmptyMsg}}</div>
    <div class="bottom_button" @click="clickButton">{{buttonText}}</div>
  </div>
</template>
<script>
export default {
  props:{
    buttonText:{
      type: String,
      default: ""
    },
    EmptyMsg:{
      type: String,
      default: ""
    }
  },
  methods:{
    clickButton(){
      this.$emit('clickButton')
    }
  }
}
</script>
<style lang="scss" scoped>
.empty{
  text-align: center;
  height: 100%;
  background-color: #fff;
  .empty_msg{
    position: fixed;
    top:323px;
    font-size: 13px;
    color:#767676;
    width:100%;
  }
  .bottom_button{
    position: fixed;
    bottom:12px;
    left: 12px;
    right: 12px;
    border-radius: 5px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    color:#fff;
    background:linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
  }
}
</style>